<template>
  <div class="hello">
    <div class="main min-height">
      咖聘|招聘隐私权条款 咖聘|招聘（以下亦称“我们”）尊重并保护所有使用
      咖聘|招聘平台服务的用户的个人隐私权，在您使用
      咖聘|招聘提供的服务时（下称“服务”），我们将按照本隐私权条款收集、使用、共享及保护您的个人信息。本隐私权条款包含了我们收集、存储、使用、共享和保护您的个人信息的条款，我们希望通过本隐私权条款向您清晰地介绍我们对为您提供的访问、更新、控制和保护您个人信息的方式，因此请您完整地阅读本《咖聘|招聘隐私权条款》（下称“本隐私权条款”），作出您认为适当的选择。如果您不同意本隐私权条款的任何内容，您应立即停止使用
      咖聘|招聘平台服务。当您使用
      咖聘|招聘平台提供的任一服务时，即表示您已同意我们按照本隐私权条款来合法使用和保护您的个人信息。
      <br />
      一、定义<br />
      1、咖聘|招聘平台，是指旗下运营之
      咖聘|招聘网站(www.kphr.com.cn)及咖聘|招聘微信小程序。 <br />2、关联方：指
      咖聘|招聘平台的经营者及其他与 咖聘|招聘存在关联关系的公司的单称或合称。
      二、适用范围 <br />为用户提供更好、更优、更个性化的服务是
      咖聘|招聘坚持不懈的追求，也希望通过我们提供的服务可以使您的生活更方便。本隐私权条款适用于咖聘|招聘提供的所有服务，您访问
      咖聘|招聘平台网站或登陆相关客户端使用
      咖聘|招聘平台提供的服务，均适用本隐私权条款。
      需要特别说明的是，本隐私权条款不适用于其他第三方向您提供的服务，例如
      咖聘|招聘平台上的商户依托
      咖聘|招聘平台向您提供服务时，您向商户提供的个人信息不适用于本隐私权条款。
      三、信息收集<br />
      我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。<br />
      1、您向我们提供的信息。<br />
      当您注册咖聘|招聘账户时填写及/或使用我们提供的服务时提交的信息，包括但不限于您的电话号码、用户名信息、简历信息及公司信息等信息。<br />
      2、在您使用服务过程中收集的信息。<br />
      为了提供并优化您需要的服务，我们会收集您使用服务的相关信息，这类信息包括：<br />
      1）在您使用<br />
      咖聘|招聘平台服务，或访问咖聘|招聘平台网页时，咖聘|招聘自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、实际所在位置的信息、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据;如您下载或使用
      咖聘|招聘或其关联公司客户端软件， 或访问移动网页使用
      咖聘|招聘平台服务时，咖聘|招聘可能会读取与您位置和移动设备相关的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。<br />
      2）在使用 咖聘|招聘平台服务，或访问 咖聘|招聘平台网页时， 请您不要主动向
      咖聘|招聘提供您的个人敏感信息（如种族、宗教、政治立场、个人健康和医疗信息、性取向等），否则
      咖聘|招聘不保证被意外收集的可能性。<br />
      3）在您使用
      咖聘|招聘平台提供的专项服务时，我们会依据专项服务的要求收集您的其他必要信息。我们将严格按照法律法规的要求进行信息的收集。您应确保您填写或提交的身份信息的真实性，否则
      咖聘|招聘可选择拒绝为您提供相关的服务。
      <br />
      4）除上述信息外，我们还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与我们的客户服务团队联系时所提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与
      咖聘|招聘的关联方、咖聘|招聘合作伙伴之间互动时我们收集的相关信息。与此同时，为提高您使用
      咖聘|招聘平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病
      毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的
      URL。
      <br />
      3、来自第三方的信息<br />
      为了给您提供更好、更优、更加个性化的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，咖聘|招聘的关联方、合作伙伴会依据法律的规定或与您的约定或征得您同意的前提下，向咖聘|招聘分享您的个人信息。<br />
      您了解并同意，以下信息不适用本隐私权条款：<br />
      1）您在使用 咖聘|招聘平台提供的站内搜索服务时输入的关键字信息;<br />
      2）在您未选择“匿名评价”功能时，咖聘|招聘收集到的您在咖聘|招聘平台进行评价的有关数据，包括但不限于用户名、商品名称及评价详情等;<br />
      3）信用评价、违反法律规定或违反 咖聘|招聘规则行为及
      咖聘|招聘已对您采取的措施;<br />
      4）应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息。
      您知悉并认可：咖聘|招聘可能会与第三方合作向用户提供相关的网络服务，在此情况下，如
      咖聘|招聘旗下相应主体已与该第三方签署了适当的保密条款，则
      咖聘|招聘有权将用户的注册信息等资料提供给该第三方，并要求该第三方按照我们的隐私权条款以及其他任何相关的保密和安全措施来为我们处理上述信息。另外，在不透露单个用户隐私资料的前提下，咖聘|招聘有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。咖聘|招聘可能会对网络服务使用情况进行统计。同时，咖聘|招聘可能会与公众分享这些统计信息，以展示我们服务的整体使用趋势。这些统计信息不包含您的任何身份识别信息。<br />
      四、信息使用的目的和方式<br />
      因收集您的信息是为了向您提供服务及提升服务质量的目的，为了实现这一目的，我们会把您的信息用于下列用途：<br />
      1、向您提供您使用的各项服务，并维护、改进这些服务。<br />
      2、我们可能使用您提供的个人信息中的联系方式与您通信，
      例如通知您有关您的账户、安全性更新、产品和服务信息。
      为了更好的为您提供服务，请您及时查看 咖聘|招聘发送的相关信息。<br />
      3、向您推荐您可能感兴趣的内容，包括但不限于通过系统向您展示个性化的第三方推广信息，或者根据本隐私权条款与咖聘|招聘的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。如果您对我们推荐的内容不感兴趣，请及时回复取消内容的通知。<br />
      4、我们可能使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联方协议、政策或规则的行
      为，以保护您、其他我们用户，或我们或其关联方的合法权益。<br />
      5、我们可能会将来自某项服务的个人信息与来自其他服务的信息结合起来，用于为了给您提供更加个性化的服务使用，例如让您拥有更广泛的社交圈的需要而使用、共享或披露。<br />
      6、咖聘|招聘不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的同意，或该第三方和咖聘|招聘及其关联方单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。<br />
      7、如果您向 咖聘|招聘提交了您的简历信息，您同意使用
      咖聘|招聘平台上相应服务的招聘企业或人员可以通过我们的简历数据库找到您的简历。当您在
      咖聘|招聘填写或提交您的简历信息，并选择了公开您的简历时，你已经同意向使用
      咖聘|招聘平台上相应服务的招聘企业或人员拥有法律上许可的对您的简历进行查询及使用的权利。对于因此而引起的任何法律纠纷（包括但不限于招聘企业或人员错误或非法使用前述简历信息），咖聘|招聘不承担任何法律责任。
      尽管
      咖聘|招聘已经作了相应的安全防范措施，仍可能不可避免某一第三方躲过了我们的安全措施并进入我们的数据库查找到您的简历。咖聘|招聘认为在您把您的简历放入我们的数据库时，您已经意识到并同意承担这样的风险。对于因此而引起的任何法律纠纷，咖聘|招聘不承担任何法律责任。
      您知悉并认可：咖聘|招聘通过广告或其他方式向您提供链接，
      使您可以接入第三方服务或网站。您使用该等第三方的服务时，须受该第三方的服务条款及隐私条款约束，您需要仔细阅读其条款。本协议仅适用于
      咖聘|招聘提供的服务器所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，咖聘|招聘对任何第三方使用由您提供的信息不承担任何责任。<br />
      五、信息共享<br />
      我们对您的信息承担保密义务，我们仅会在下列情况下才将您的信息与第三方共享：<br />
      1、事先获得您或您的监护人的同意或授权;<br />
      2、为免除您在生命、身体或财产方面的紧急危险;<br />
      3、根据法律法规的规定或行政、司法机构的要求;<br />
      4、向 咖聘|招聘的关联方分享您的信息;
      <br />5、向合作伙伴提供您的信息，前提是
      咖聘|招聘旗下相应主体已与该第三方签署了适当的保密条款;<br />
      6、以学术研究或公共利益为目的，且无害于您的重大利益;<br />
      7、如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷;<br />
      8、只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议。例如您在
      咖聘|招聘上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求
      的，咖聘|招聘会视情况向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决;<br />
      9、如您出现违反中国有关法律、法规或者 咖聘|招聘相关协议
      或相关规则的情况，需要向第三方披露;<br />
      10、为维护 咖聘|招聘及其关联方或用户的合法权益;<br />
      11、为完成合并、分立、收购或资产转让;<br />
      12、您通过点击相关产品的相应按键同意向第三方提交个人信息（包括但不限于“申请贷款”、“帮我申请”、“帮我提交”等按键）;<br />
      13、为更好地向您提供服务所需的其他情形。
      因此，请您谨慎考虑通过我们的服务上传、发布和交流的信息内容。在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。如要求从我们的服务中删除您的相关信息，请通过该等特别服务条款提供的方式操
      作。<br />
      六、Cookie 和网络 Beacon 的使用 <br />为使您获得更轻松的访问体验，您访问
      咖聘|招聘平台相关网站或使用
      咖聘|招聘平台提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，
      或者帮助判断您的账户安全。这些数据文件可能是 Cookie， Flash
      Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
      请您理解，我们的某些服务只能通过使用“Cookie”才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以在相关的页面修改对
      Cookie 的接受程度或者拒绝 咖聘|招聘的
      Cookie，但这一举动在某些情况下可能会影响您安全访问 咖聘|招聘相关网站和使用
      咖聘|招聘平台提供的服务。 网页上常会包含一些电子图象（称为“单像素” GIF
      文件或“网络 beacon”），使用网络 beacon
      可以帮助网站计算浏览网页的用户或访问某些 cookie，我们会通过网络 beacon
      收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。
      咖聘|招聘可能使用您在
      咖聘|招聘网站浏览的行为数据，根据您的兴趣喜好，在您浏览其他网站时有可能展现与您兴趣相关的广告内容，这是
      咖聘|招聘使用的广告定向功能，这个过程包括以下几个方面：<br />
      1)兴趣数据的收集：我们通过您在 咖聘|招聘浏览过的内容，使用 Cookie
      收集并保存您的行为数据。<br />
      2)根据您的浏览行为数据，获取您的兴趣分类。例如您最近两天寻找北京简历信息，我们会了解您的兴趣是北京的简历信息。<br />
      3)广告定向功能：我们了解您的兴趣后，当您浏览其他网站时，咖聘|招聘可能通过
      RTB
      广告系统，向您推送简历相关的信息，以满足您的兴趣需要。如果您不希望在其他网站中看到针对用户兴趣投放的咖聘|招聘推广内容，请点击禁用/关闭。<br />
      七、信息存储<br />
      咖聘|招聘收集的有关您的信息和资料将保存在
      咖聘|招聘及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或
      咖聘|招聘收集信息和资料所在地并在该地被访问、存储和展示。<br />
      八、您的个人信息的查询及变更<br />
      1、无论您何时使用我们的服务，我们都会力求让您顺利访问自己的个人信息。如果这些信息有误，我们会努力提供各种方式来让您快速更新信息或删除信息（除非我们出于合法业务或法律方面的原因而必须保留这些信息）。您可以自行登录您的账户对您的个人信息进行查询及更新。如果您认为
      咖聘|招聘存在违反法律、行政法规的规定或者与您的约定收集、使用您个人信息的，您可以通过
      咖聘|招聘网站首页下方的微信及企业邮箱与我们取得联系。更新您的个人信息时，我们可能会先要求您验证自己的身份，然后再处理您的请求。对于那些无端重复、需要过多技术手段（例如需要开发新系统或从根本上改变现行惯例）、给他人隐私权带来风险或者非常不切实际（例如涉及备份磁带上存放的信
      息）的请求，我们可能会予以拒绝。<br />
      2、只要我们能够让您访问和修改信息，而且其不需要过多投入，则我们会免费提供。我们力求对服务进行完善的维护，以保护信息免遭意外或恶意的破坏。因此，当您从我们的服务中删除信息后，我们可能不会立即从在用的服务器中删除这些信息的残留副本，也可能不会从备份系统中删除相应的信息。<br />
      九、您的个人信息保护<br />
      1、我们仅在本隐私条款所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。咖聘|招聘会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，咖聘|招聘将尽力做到使您的信息不被泄漏、毁损或丢失。<br />
      2、您的账户均有安全保护功能，请妥善保管您的账户及密码信息。咖聘|招聘将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解，由于技术的限制以及可能存在的各种恶意手段，即便竭尽所能加强安全措施，在信息网络上也不存在“完善的安全措施”。如因您自己的原因导致账户及密码信息泄露而造成的任何法律后果需由您本人负责。<br />
      3、在使用
      咖聘|招聘平台服务进行网上交易时，如您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息（例如
      联系人、联络方式等），请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息已经被泄
      露或者存在被泄露的可能，且有可能会危及您注册获得的
      咖聘|招聘账户安全，或者给您造成其他的损失的，请您务必在第一时间通知
      咖聘|招聘客服，以便 咖聘|招聘采取相应措施维护您的
      咖聘|招聘账户安全，防止损失的发生或者进一步扩大。为避免疑义，
      如果您未在第一时间通知
      咖聘|招聘客服，则您应自行承担由此给您造成的损失（及扩大的损失）。<br />
      十、未成年人保护<br />
      未满十八岁的未成年人应在其父母或监护人指导下使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本隐私权条款并建议未成年人在提交的个人信息之前寻求父母或监护人的同意和指导，未成年人一旦使用本网站，我们视为有未成年人父母或监护人的同意或指导。<br />
      十一、其他<br />
      1、如您对本隐私权条款有任何疑问或您提供的上述个人信息受到了侵扰，您可以通过
      咖聘|招聘网站首页下方的违法信息举报电话与我们联系，我们将在接到投诉后尽快给予您答复。<br />
      2、咖聘|招聘有权对本隐私权条款进行更新，以反映我们的信息操作的变更。如果我们对其作了任何重大变更，我们会在该等变更生效之前，通过站内信、邮件或在网站上挂出通知的方式通知您，您仍继续使用
      咖聘|招聘提供的服务，即表示您同意受经修订本隐私权条款的约束。我们鼓励您定期地查阅本网页上关于我们隐私实践的最新信息。<br />
      3、咖聘|招聘将致力于保障您个人信息的私密性和安全性，并让您能够自主控制。本隐私权条款属于《咖聘|招聘使用协议》不可分割的有效组成部分。<br />

      <div class="btjh">
        <el-button @click="calk">确认阅读并返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      // msg: 'Welcome to Your Vue.js App'
    }
  },
  methods: {
    calk() {
      this.$router.go(-1)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  font-size: 14px;
  background: #fff;
  width: 1300px;
  margin: 0 auto;
  padding: 50px;
}
.main h4 {
  width: 1300px;
  margin: 0 auto;
}

.main section {
  width: 1300px;
  margin: 0 auto;
}

.main p {
  width: 1300px;
  margin: 0 auto;
}

section {
  padding: 10px 0;
}
.btjh {
  text-align: center;
  margin-top: 20px;
}
</style>
